import React from 'react'
import ReactDOM from 'react-dom/client'
import { CookiesProvider } from 'react-cookie'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import reportWebVitals from './reportWebVitals'
import './index.css'
import App from './App'
import ErrorPage from './pages/ErrorPage'
import Home from './pages/Home'
import AuthenticatedRoute from './pages/AuthRoute'

import { AuthContextProvider } from './module/auth/context'
import { HomeContextProvider } from './module/home/context'
import { DomainContextProvider } from './module/domain/context'
import MainFrame from './pages/MainFrame'
import ExceptionPathRoute from './pages/ExceptionPathRoute'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: (
      <ExceptionPathRoute>
        <ErrorPage />
      </ExceptionPathRoute>
    ),
    children: [
      {
        index: true,
        path: '/',
        element: (
          <AuthenticatedRoute>
            <Home />
          </AuthenticatedRoute>
        ),
      },
      {
        path: '/:productId',
        element: (
          <AuthenticatedRoute navigate>
            <MainFrame />
          </AuthenticatedRoute>
        ),
      },
    ],
  },
])

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthContextProvider>
        <HomeContextProvider>
          <DomainContextProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </DomainContextProvider>
        </HomeContextProvider>
      </AuthContextProvider>
    </CookiesProvider>
  </React.StrictMode>,
)

reportWebVitals()
