import { match } from 'path-to-regexp'
import { useLocation } from 'react-router-dom'

import { ReturnRegExpMatchParams, RegExpMatchParams } from '@/types'

function useRegExpMatch(): ReturnRegExpMatchParams | undefined {
  const { pathname } = useLocation()

  const emailConfigMatchFn = match<RegExpMatchParams>('/:productId/:domain+/list')
  const emailConfigMatchRes = emailConfigMatchFn(pathname)

  if (emailConfigMatchRes) {
    const productId = emailConfigMatchRes.params.productId
    const encodeDomain = emailConfigMatchRes.params.domain.join('%2F')

    return {
      path: `/${productId}/${encodeDomain}/list`,
      params: {
        productId,
        domain: encodeDomain,
      },
    }
  }

  const configMatchFn = match<RegExpMatchParams>('/:productId/:domain+')
  const configMatchRes = configMatchFn(pathname)

  if (configMatchRes) {
    const productId = configMatchRes.params.productId
    const encodeDomain = configMatchRes.params.domain.join('%2F')

    return {
      path: `/${productId}/${encodeDomain}`,
      params: {
        productId,
        domain: encodeDomain,
      },
    }
  }
}

export default useRegExpMatch
