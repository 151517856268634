import React from 'react'

type UserListHeaderProps = {
  userTotalCount: number
  userFilteredCount: number
  onAddDomainClick: () => void
}

function DomainListHeader({
  userTotalCount,
  userFilteredCount,
  onAddDomainClick,
}: UserListHeaderProps) {
  return (
    <div className='flex items-center justify-between w-full'>
      <div className='mx-6 space-x-2'>
        <span className='text-xs badge badge-ghost'>전체 사용자 {userTotalCount}</span>
        <span className='text-xs badge badge-ghost'>검색된 사용자 {userFilteredCount}</span>
      </div>
    </div>
  )
}

export default DomainListHeader
