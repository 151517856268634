import React, { useEffect, useState } from 'react'
import { useDomainList } from '@/module/domain/hook'
import { UserInfo } from '@/module/domain/types'
import EmptyList from './common/EmptyList'

type DomainListProps = {
  list: UserInfo[]
  onClickListItem: (domain: UserInfo) => void
  onClickEditDomainPage: (domain: UserInfo) => void
}

function DomainList({ list, onClickListItem, onClickEditDomainPage }: DomainListProps) {
  return (
    <ul className='px-4 text-sm menu text-base-content grow'>
      {list.length === 0 && <EmptyList />}
      {list.map((domainInfo) => (
        <li key={domainInfo.Username}>
          <a
            onClick={(e) => {
              onClickListItem(domainInfo)
            }}
          >
            <DomainListItem domain={domainInfo} onClickEditDomainPage={onClickEditDomainPage} />
          </a>
        </li>
      ))}
    </ul>
  )
}

type DomainListItemProps = {
  domain: UserInfo
  onClickEditDomainPage: (domain: UserInfo) => void
}

function DomainListItem({ domain, onClickEditDomainPage }: DomainListItemProps) {
  const [open, setOpen] = useState<boolean>(false)
  const { selectedDomain, setSelectedDomain } = useDomainList()

  useEffect(() => {
    setOpen(domain.Username === selectedDomain?.Username)
  }, [selectedDomain])

  return (
    <div
      className='w-full collapse'
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <input
        type='checkbox'
        className='!min-h-fit'
        checked={open}
        onChange={(e) => {
          setSelectedDomain(e.target.checked ? domain : null)
        }}
      />
      <div className='p-0 !min-h-fit collapse-title'>
        <div className='flex items-center justify-between'>
          <div className={`${open ? 'font-semibold' : ''}`}>
            <div>
              종류: {domain.provider} 📎 {domain.email}
            </div>
            <div className='text-gray-400'>{domain.Username}</div>
          </div>
          <div className='flex flex-col'>
            <div className='flex justify-end text-xs text-gray-400'>{domain.UserStatus ?? ''}</div>
          </div>
        </div>
      </div>
      <div className='p-0 mt-2 -mb-4 text-xs text-gray-400 collapse-content'>
        <div className='grid grid-flow-col gap-2 grid-row-2 justify-items-stretch'>
          <p className='w-full whitespace-pre-line'>{domain.UserCreateDate} 생성</p>
          <div className='w-full'>{domain.UserLastModifiedDate} 최종 수정</div>
          <div className='w-[30rem] row-span-2 justify-self-end'>
            <div className='flex justify-between'>
              <span>fid: </span>
              <span>{domain.fid ?? ''}</span>
            </div>
            <div className='flex justify-between'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DomainList
