import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@/module/auth/hook'
import { menuList } from '@/module/home/constant'
import { useSelected } from '@/module/home/hook'
import { ProductType } from '@/types'
import { Paths } from '@/api/paths'

function Home() {
  const { isAuth, user } = useAuth()
  const { setSelectedProductType } = useSelected()

  return (
    <div className='flex items-center justify-center w-full'>
      <div className='min-h-screen hero bg-slate-200'>
        <div className='text-center hero-content'>
          <div className='max-w-md'>
            <h1 className='text-3xl font-bold uppercase'>Sconn User Manager</h1>
            <p className='py-6'>
              안녕하세요. {isAuth ? `${user.name}님, ` : ''}스콘 유저 관리 페이지입니다.
            </p>
            {isAuth ? (
              <div>
                <p>상품을 선택하세요.</p>
                <ul className='p-2 menu menu-compact rounded-box'>
                  {menuList.map((menu) => (
                    <li
                      key={menu.id}
                      onClick={() => {
                        setSelectedProductType(menu.id as ProductType)
                      }}
                    >
                      <Link to={`/${menu.id}`}>{menu.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div
                className='gap-2 btn btn-primary'
                onClick={() => {
                  const res = window.open(
                    (process.env.REACT_APP_API_SERVER_URL as string) + Paths.SIGNIN,
                    'Sconn User Manager Login',
                    'popup=true,width=450,height=480',
                  )

                  if (!res) return

                  const handleAuthMessage = (event: MessageEvent) => {
                    // TODO: event.origin 은 서버니까 다른 처리 필요
                    // 서버에서 redirection 해주기 때문이기도 해서 서버에서는 json 만 내려주도록 수정해도 됨
                    // if (event.origin !== window.location.origin) return

                    window.removeEventListener('message', handleAuthMessage)
                    if (event.data === 'auth-success') {
                      const params = new URLSearchParams(window.location.search)
                      const redirectTo = params.get('redirectTo')
                      if (redirectTo?.endsWith('/list')) {
                        params.set('redirectTo', redirectTo.slice(0, -5))
                      }
                      params.set('loggedIn', 'true')
                      const decodeParams = decodeURIComponent(params.toString())
                      window.location.replace(`/?${decodeParams}`)
                    } else if (event.data === 'auth-failure') {
                      alert('로그인에 실패했습니다.')
                    }
                  }

                  window.addEventListener('message', handleAuthMessage)

                  const checkPopupClosed = setInterval(() => {
                    if (res.closed) {
                      clearInterval(checkPopupClosed)
                      window.removeEventListener('message', handleAuthMessage)
                    }
                  }, 500)
                }}
              >
                <i className='fa-brands fa-google'></i>
                <span>구글로 로그인하기</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
