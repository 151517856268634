import { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { DomainInfo, UserInfo } from '../domain/types'
import { homeReducer } from './reducer'
import { Action, HomeState } from './types'

type HomeDispatch = Dispatch<Action>
export const HomeStateContext = createContext<HomeState | null>(null)
export const HomeDispatchContext = createContext<HomeDispatch | null>(null)

const defaultDate = new Date('2025-08-01 00:00:00')

export const initialDomain: DomainInfo = {
  domain: '',
  name: '',
  maxLicense: 0,
  userCount: 0,
  description: '',
  expireTime: defaultDate.toISOString().substring(0, 10),
  updateTime: '',
  createTime: '',
}

export const initialUser: UserInfo = {
  Enabled: false,
  UserStatus: 'UNCONFIRMED',
  UserCreateDate: '',
  UserLastModifiedDate: '',
  Username: '',
  emailVerified: false,
  fid: '',
  locale: '',
  email: '',
}

export const initialState: HomeState = {
  opened: {
    productDropdown: false,
    profileDropdown: false,
    domainAddModal: false,
    emailAddModal: false,
    sidemenu: false,
  },
  selected: {
    productType: 'edu',
    domain: initialUser,
    validEmailType: false,
  },
}

export function HomeContextProvider({ children }: { children: ReactNode }) {
  const [home, dispatch] = useReducer(homeReducer, initialState)

  return (
    <HomeDispatchContext.Provider value={dispatch}>
      <HomeStateContext.Provider value={home}>{children}</HomeStateContext.Provider>
    </HomeDispatchContext.Provider>
  )
}
