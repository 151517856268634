export type DomainState = {
  domain: {
    itemsAll: UserInfo[]
    items: UserInfo[]
    selected: UserInfo | null
    count: number
    userCount: number
    filterText: string
    nextToken: string | null
  }
  domainValidEmail: {
    itemsAll: string[]
    items: string[]
    selected: string | null
    count: number
    filterText: string
  }
  loading: {
    save: boolean
    delete: boolean
  }
  userTotalCount: number
}

export type ValidType = 'email' | 'domain'

export type AttributesType = Record<'Name' | 'Value', string>

export type ValidEmails = {
  emails: string[]
}

/**
 * maxLicense:  undefined인 경우 앱에서는 '무제한'으로 표시
 * userCountingTime, userCount가 undefined일 경우 앱에서는 '등록 유저 없음'으로 표시
 * domain: key 역할
 * validType: email / domain(혹은 없음) | Education 만 해당
 */
export type DomainInfoDto = {
  validType?: ValidType
  maxLicense?: number
  userCountingTime?: number
  userCount?: number
  updateTime?: number
  createTime: number
  description: string
  name: string
  expireTime: number
  domain: string
}

export type UserInfoDto = {
  Attributes?: AttributesType[]
  Enabled?: boolean
  UserCreateDate: string
  UserLastModifiedDate: string
  UserStatus?: string
  Username: string
}

export type UserInfoListDto = {
  users: UserInfoDto[]
  nextToken?: string
}

export type CreateDomainInfoDto = {
  validType?: ValidType
  maxLicense?: number
  createTime: number
  description: string
  name: string
  expireTime: number
  domain: string
}

export type CreateDomainValidEmailDto = {
  domain: string
  body: ValidEmails
}

export type DeleteDomainValidEmailDto = CreateDomainValidEmailDto

export type PutDomainInfoDto = CreateDomainInfoDto

export type DeleteDomainDto = {
  domain: string
}

export type DomainInfo = {
  validType?: ValidType
  maxLicense?: number
  userCountingTime?: string
  userCount?: number
  updateTime?: string
  createTime: string
  description: string
  name: string
  expireTime: string
  domain: string
}

export type UserInfo = {
  Enabled?: boolean
  UserStatus?: string
  UserCreateDate: string
  UserLastModifiedDate: string
  Username: string
  emailVerified?: boolean
  fid?: string
  locale?: string
  email?: string
  provider?: string
}

export const DOMAIN_SET_DOMAIN_LIST = 'DOMAIN/SET_OPEN_DOMAIN_LIST' as const
export const DOMAIN_SET_USER_LIST = 'DOMAIN/SET_USER_LIST' as const
export const DOMAIN_SET_SELECTED_DOMAIN = 'DOMAIN/SET_SELECTED_DOMAIN' as const
export const DOMAIN_SET_FILTER_TEXT = 'DOMAIN/SET_FILTER_TEXT' as const
export const DOMAIN_FILTER_DOMAIN_LIST = 'DOMAIN/FILTER_DOMAIN_LIST' as const
export const DOMAIN_SET_DOMAIN_COUNT = 'DOMAIN/SET_DOMAIN_COUNT' as const
export const DOMAIN_SET_DOMAIN_USER_COUNT = 'DOMAIN/SET_DOMAIN_USER_COUNT' as const
export const DOMAIN_SET_LOADING_SAVE = 'DOMAIN/SET_LOADING_SAVE' as const
export const DOMAIN_SET_LOADING_DELETE = 'DOMAIN/SET_LOADING_DELETE' as const
export const DOMAIN_SET_VALID_EMAIL_LIST = 'DOMAIN/SET_VALID_EMAIL_LIST' as const
export const DOMAIN_SET_SELECTED_VALID_EMAIL = 'DOMAIN/SET_SELECTED_VALID_EMAIL' as const
export const DOMAIN_SET_VALID_EMAIL_FILTER_TEXT = 'DOMAIN/SET_VALID_EMAIL_FILTER_TEXT' as const
export const DOMAIN_FILTER_VALID_EMAIL_LIST = 'DOMAIN/FILTER_VALID_EMAIL_LIST' as const
export const DOMAIN_SET_VALID_EMAIL_COUNT = 'DOMAIN/SET_VALID_EMAIL_COUNT' as const
export const DOMAIN_SET_USER_TOTAL_COUNT = 'DOMAIN/SET_USER_TOTAL_COUNT' as const
export const DOMAIN_SET_USER_LIST_MORE = 'DOMAIN/SET_USER_LIST_MORE' as const

export type Action =
  | { type: typeof DOMAIN_SET_DOMAIN_LIST; domainInfoList: UserInfoDto[] }
  | { type: typeof DOMAIN_SET_USER_LIST; userInfoList: UserInfoListDto }
  | { type: typeof DOMAIN_SET_SELECTED_DOMAIN; selectedDomain: UserInfo | null }
  | {
      type: typeof DOMAIN_SET_FILTER_TEXT
      filterText: string
    }
  | {
      type: typeof DOMAIN_FILTER_DOMAIN_LIST
    }
  | {
      type: typeof DOMAIN_SET_DOMAIN_COUNT
      domainCount: number
    }
  | {
      type: typeof DOMAIN_SET_DOMAIN_USER_COUNT
      domainUserCount: number
    }
  | {
      type: typeof DOMAIN_SET_LOADING_SAVE
      loadingSave: boolean
    }
  | {
      type: typeof DOMAIN_SET_LOADING_DELETE
      loadingDelete: boolean
    }
  | {
      type: typeof DOMAIN_SET_VALID_EMAIL_LIST
      validEmailList: string[]
    }
  | {
      type: typeof DOMAIN_SET_SELECTED_VALID_EMAIL
      selectedValidEmail: string
    }
  | {
      type: typeof DOMAIN_SET_VALID_EMAIL_FILTER_TEXT
      filterText: string
    }
  | {
      type: typeof DOMAIN_FILTER_VALID_EMAIL_LIST
    }
  | {
      type: typeof DOMAIN_SET_VALID_EMAIL_COUNT
      validEmailCount: number
    }
  | {
      type: typeof DOMAIN_SET_USER_TOTAL_COUNT
      userTotalCount: number
    }
  | {
      type: typeof DOMAIN_SET_USER_LIST_MORE
      userInfoList: UserInfoListDto
    }
