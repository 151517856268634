import {
  DomainInfo,
  DOMAIN_FILTER_VALID_EMAIL_LIST,
  DOMAIN_SET_SELECTED_DOMAIN,
  DOMAIN_SET_SELECTED_VALID_EMAIL,
  DOMAIN_SET_VALID_EMAIL_COUNT,
  DOMAIN_SET_VALID_EMAIL_FILTER_TEXT,
  DOMAIN_SET_VALID_EMAIL_LIST,
  UserInfoDto,
  UserInfo,
  DOMAIN_SET_USER_TOTAL_COUNT,
  UserInfoListDto,
  DOMAIN_SET_USER_LIST,
  DOMAIN_SET_USER_LIST_MORE,
} from '@/module/domain/types'
import { useContext } from 'react'
import { DomainDispatchContext, DomainStateContext } from './context'
import {
  DOMAIN_FILTER_DOMAIN_LIST,
  DOMAIN_SET_DOMAIN_COUNT,
  DOMAIN_SET_DOMAIN_LIST,
  DOMAIN_SET_DOMAIN_USER_COUNT,
  DOMAIN_SET_FILTER_TEXT,
  DOMAIN_SET_LOADING_DELETE,
  DOMAIN_SET_LOADING_SAVE,
} from './types'

function useDomainState() {
  const state = useContext(DomainStateContext)
  if (state == null) throw new Error('DomainProvider not found')
  return state
}

function useDomainDispatch() {
  const dispatch = useContext(DomainDispatchContext)
  if (dispatch == null) throw new Error('DomainProvider not found')
  return dispatch
}

export function useDomainLoading() {
  const { loading } = useDomainState()
  const dispatch = useDomainDispatch()

  const setIsSaveLoading = (loadingSave: boolean) => {
    dispatch({
      type: DOMAIN_SET_LOADING_SAVE,
      loadingSave,
    })
  }

  const setIsDeleteLoading = (loadingDelete: boolean) => {
    dispatch({
      type: DOMAIN_SET_LOADING_DELETE,
      loadingDelete,
    })
  }

  return {
    loading,
    setIsSaveLoading,
    setIsDeleteLoading,
  }
}

export function useDomainList() {
  const { domain, userTotalCount } = useDomainState()
  const dispatch = useDomainDispatch()
  const filterText = domain.filterText
  const selectedDomain = domain.selected
  const nextToken = domain.nextToken

  const setUserList = (userInfoList: UserInfoListDto) => {
    dispatch({
      type: DOMAIN_SET_USER_LIST,
      userInfoList,
    })
  }

  const setDomainList = (domainInfoList: UserInfoDto[]) => {
    dispatch({
      type: DOMAIN_SET_DOMAIN_LIST,
      domainInfoList,
    })
  }

  const setSelectedDomain = (selectedDomain: UserInfo | null) => {
    dispatch({
      type: DOMAIN_SET_SELECTED_DOMAIN,
      selectedDomain,
    })
  }

  const setFilterText = (filterText: string) => {
    dispatch({
      type: DOMAIN_SET_FILTER_TEXT,
      filterText,
    })
  }

  const filterDomainList = () => {
    dispatch({
      type: DOMAIN_FILTER_DOMAIN_LIST,
    })
  }

  const setDomainCount = (domainCount: number) => {
    dispatch({
      type: DOMAIN_SET_DOMAIN_COUNT,
      domainCount,
    })
  }

  const setDomainUserCount = (domainUserCount: number) => {
    dispatch({
      type: DOMAIN_SET_DOMAIN_USER_COUNT,
      domainUserCount,
    })
  }

  const setUserTotalCount = (userTotalCount: number) => {
    dispatch({
      type: DOMAIN_SET_USER_TOTAL_COUNT,
      userTotalCount,
    })
  }

  const setUserListMore = (userInfoList: UserInfoListDto) => {
    dispatch({
      type: DOMAIN_SET_USER_LIST_MORE,
      userInfoList,
    })
  }

  return {
    domain,
    filterText,
    selectedDomain,
    setDomainList,
    setSelectedDomain,
    setFilterText,
    filterDomainList,
    setDomainCount,
    setDomainUserCount,
    userTotalCount,
    setUserTotalCount,
    setUserList,
    setUserListMore,
    nextToken,
  }
}

export function useDomainValidEmailList() {
  const { domainValidEmail } = useDomainState()
  const dispatch = useDomainDispatch()
  const filterText = domainValidEmail.filterText

  const setEmailList = (validEmailList: string[]) => {
    dispatch({
      type: DOMAIN_SET_VALID_EMAIL_LIST,
      validEmailList,
    })
  }

  const setSelectedEmail = (selectedValidEmail: string) => {
    dispatch({
      type: DOMAIN_SET_SELECTED_VALID_EMAIL,
      selectedValidEmail,
    })
  }

  const setFilterText = (filterText: string) => {
    dispatch({
      type: DOMAIN_SET_VALID_EMAIL_FILTER_TEXT,
      filterText,
    })
  }

  const filterEmailList = () => {
    dispatch({
      type: DOMAIN_FILTER_VALID_EMAIL_LIST,
    })
  }

  const setEmailCount = (validEmailCount: number) => {
    dispatch({
      type: DOMAIN_SET_VALID_EMAIL_COUNT,
      validEmailCount,
    })
  }

  return {
    domainValidEmail,
    filterText,
    setEmailList,
    setSelectedEmail,
    setFilterText,
    filterEmailList,
    setEmailCount,
  }
}
