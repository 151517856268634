import { UserInfoDto, UserInfoListDto, ValidEmails } from '@/module/domain/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import {
  createEduValidEmails,
  createUser,
  deleteEduValidEmails,
  deleteUser,
  fetchEduValidEmailList,
  fetchUserList,
  fetchUserListMore,
  fetchUserSignOut,
  fetchUserTotalCount,
  modifyUser,
} from './domain'
import useRegExpMatch from '@/components/common/hooks/useRegExpMatch'
import { useContext } from 'react'
import { DomainStateContext } from '@/module/domain/context'

export function useUserAPI() {
  const { productId } = useParams()
  const state = useContext(DomainStateContext)
  const filterText = state?.domain.filterText
  const nextToken = state?.domain.nextToken

  const queryClient = useQueryClient()

  const userQuery = useQuery<UserInfoListDto, AxiosError>(
    ['user', productId, filterText],
    async () => {
      return await fetchUserList(productId, filterText)
    },
    { staleTime: 1000 * 60 * 5, suspense: true, retry: 0 },
  )

  const userQueryMore = useQuery<UserInfoListDto, AxiosError>(
    ['user', 'more', productId, filterText, nextToken],
    async () => {
      return await fetchUserListMore(productId, filterText, nextToken)
    },
    { enabled: false, staleTime: 1000 * 60 * 5, suspense: true, retry: 0 },
  )

  const userSignOut = useMutation({
    mutationFn: fetchUserSignOut,
  })

  const postMutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', productId] })
    },
  })

  const putMutation = useMutation({
    mutationFn: modifyUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', productId] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', productId] })
    },
  })

  return { postMutation, putMutation, deleteMutation, userQuery, userQueryMore, userSignOut }
}

export function useEmailListAPI() {
  const matches = useRegExpMatch()
  const domain = matches?.params.domain

  const queryClient = useQueryClient()
  const query = useQuery<ValidEmails, AxiosError>(
    ['emails', domain],
    async () => {
      if (domain) {
        return await fetchEduValidEmailList(domain)
      }
    },
    {
      staleTime: 1000 * 60 * 5,
      suspense: true,
      retry: 0,
    },
  )

  const postMutation = useMutation({
    mutationFn: createEduValidEmails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails', domain] })
    },
  })

  const deleteMutation = useMutation({
    mutationFn: deleteEduValidEmails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails', domain] })
    },
  })

  return { query, postMutation, deleteMutation }
}

export function useUserTotalCountAPI() {
  const { productId } = useParams()

  const userTotalCountQuery = useQuery<number, AxiosError>(
    ['userTotalCount', productId],
    async () => {
      return await fetchUserTotalCount(productId)
    },
    { staleTime: 1000 * 60 * 60 * 24, suspense: true, retry: 0 },
  )

  return { userTotalCountQuery }
}
