import { ProductType } from '@/types'
import { DomainInfo, UserInfo } from '../domain/types'

export type HomeState = {
  opened: {
    productDropdown: boolean
    profileDropdown: boolean
    domainAddModal: boolean
    emailAddModal: boolean
    sidemenu: boolean
  }
  selected: {
    productType: ProductType
    domain: UserInfo
    validEmailType: boolean
  }
}

export const HOME_SET_SELECTED_DOMAIN = 'HOME/SET_SELECTED_DOMAIM' as const
export const HOME_SET_SELECTED_PRODUCT_TYPE = 'HOME/SET_SELECTED_PRODUCT_TYPE' as const
export const HOME_SET_OPEN_PRODUCT_DROPDOWN = 'HOME/SET_OPEN_PRODUCT_NAV' as const
export const HOME_SET_OPEN_PROFILE_DROPDOWN = 'HOME/SET_OPEN_PROFILE_DROPDOWN' as const
export const HOME_SET_OPEN_DOMAIN_ADD_MODAL = 'HOME/SET_OPEN_DOMAIN_ADD_MODAL' as const
export const HOME_SET_OPEN_EMAIL_ADD_MODAL = 'HOME/SET_OPEN_EMAIL_ADD_MODAL' as const
export const HOME_SET_OPEN_SIDEMENU = 'HOME/SET_OPEN_SIDEMENU' as const
export const HOME_SET_VALID_EMAIL_TYPE = 'HOME/SET_VALID_EMAIL_TYPE' as const

export type Action =
  | { type: typeof HOME_SET_SELECTED_DOMAIN; domain: UserInfo }
  | {
      type: typeof HOME_SET_SELECTED_PRODUCT_TYPE
      productType: ProductType
    }
  | {
      type: typeof HOME_SET_OPEN_PRODUCT_DROPDOWN
      openProductDropdown: boolean
    }
  | {
      type: typeof HOME_SET_OPEN_PROFILE_DROPDOWN
      openProfileDropdown: boolean
    }
  | {
      type: typeof HOME_SET_OPEN_DOMAIN_ADD_MODAL
      openDomainAddModal: boolean
    }
  | {
      type: typeof HOME_SET_OPEN_EMAIL_ADD_MODAL
      openEmailAddModal: boolean
    }
  | {
      type: typeof HOME_SET_OPEN_SIDEMENU
      openSidemenu: boolean
    }
  | {
      type: typeof HOME_SET_VALID_EMAIL_TYPE
      validEmailType: boolean
    }
