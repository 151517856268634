import React from 'react'

type DividerProps = {
  container?: string
  children: React.ReactNode
}

function Heading({ children, container }: DividerProps) {
  return (
    <div className={`${container ?? ''} pb-2`}>
      <div className='font-bold text'>{children}</div>
    </div>
  )
}

export default Heading
