import axios from 'axios'
import {
  CreateDomainInfoDto,
  CreateDomainValidEmailDto,
  DeleteDomainDto,
  DeleteDomainValidEmailDto,
} from '@/module/domain/types'
import { PutDomainInfoDto } from './../module/domain/types'
import { Paths } from './paths'

const API_SERVER = process.env.REACT_APP_API_SERVER_URL as string

export async function fetchBizDomainList() {
  const result = await axios.get(API_SERVER + Paths.GetBizDomain, {
    withCredentials: true,
  })

  return result.data
}

export async function createBizDomain(dto: CreateDomainInfoDto) {
  return await axios.post(API_SERVER + Paths.UpdateBizDomain, dto, {
    withCredentials: true,
  })
}

export async function modifyBizDomain(dto: PutDomainInfoDto) {
  return await axios.put(API_SERVER + Paths.UpdateBizDomain, dto, {
    withCredentials: true,
  })
}

export async function deleteBizDomain(dto: DeleteDomainDto) {
  return await axios.delete(API_SERVER + Paths.UpdateBizDomain, {
    data: dto,
    withCredentials: true,
  })
}

export async function fetchEduDomainList() {
  const result = await axios.get(API_SERVER + Paths.GetEduDomain, {
    withCredentials: true,
  })

  return result.data

  // const result = await fetch('/edu-list.json').then(async (response) => await response.json())
  // return result
}

export async function createEduDomain(dto: CreateDomainInfoDto) {
  return await axios.post(API_SERVER + Paths.UpdateEduDomain, dto, {
    withCredentials: true,
  })
}

export async function modifyEduDomain(dto: PutDomainInfoDto) {
  return await axios.put(API_SERVER + Paths.UpdateEduDomain, dto, {
    withCredentials: true,
  })
}

export async function deleteEduDomain(dto: DeleteDomainDto) {
  return await axios.delete(API_SERVER + Paths.UpdateEduDomain, {
    data: dto,
    withCredentials: true,
  })
}

export async function fetchEduValidEmailList(domain: string) {
  const result = await axios.get(API_SERVER + `${Paths.GetEduEmail}/${domain}`, {
    withCredentials: true,
  })

  return result.data

  // const response = await fetch('/edu-emails-list.json')
  // const jsonData = await response.json()
  // console.log(jsonData)

  // return jsonData
}

export async function createEduValidEmails(dto: CreateDomainValidEmailDto) {
  return await axios.post(API_SERVER + `${Paths.UpdateEduEmail}/${dto.domain}`, dto.body, {
    withCredentials: true,
  })
}

export async function deleteEduValidEmails(dto: DeleteDomainValidEmailDto) {
  return await axios.delete(API_SERVER + `${Paths.UpdateEduEmail}/${dto.domain}`, {
    data: dto.body,
    withCredentials: true,
  })
}

export async function fetchUserAllList(productId?: string) {
  const result = await axios.get(API_SERVER + Paths.GetUserAll, {
    params: {
      userPoolId: productId,
    },
    withCredentials: true,
  })

  return result.data
}

export async function fetchUserList(productId?: string, filterText?: string) {
  const result = await axios.get(API_SERVER + Paths.GetUser, {
    params: {
      userPoolId: productId,
      email: filterText,
    },
    withCredentials: true,
  })

  return result.data
}

export async function fetchUserListMore(
  productId?: string,
  filterText?: string | null,
  token?: string | null,
) {
  const result = await axios.get(API_SERVER + Paths.GetUser, {
    params: {
      userPoolId: productId,
      email: filterText,
      token,
    },
    withCredentials: true,
  })

  return result.data
}

export async function fetchUserTotalCount(productId?: string) {
  const result = await axios.get(API_SERVER + Paths.GetUserTotalCount, {
    params: {
      userPoolId: productId,
    },
    withCredentials: true,
  })

  return result.data
}

export async function createUser(dto: CreateDomainInfoDto) {
  return await axios.post(API_SERVER + Paths.UpdateEduDomain, dto, {
    withCredentials: true,
  })
}

export async function modifyUser(dto: PutDomainInfoDto) {
  return await axios.put(API_SERVER + Paths.UpdateEduDomain, dto, {
    withCredentials: true,
  })
}

export async function deleteUser(dto: DeleteDomainDto) {
  return await axios.delete(API_SERVER + Paths.UpdateEduDomain, {
    data: dto,
    withCredentials: true,
  })
}

export async function fetchUserSignOut() {
  const result = await axios.get(API_SERVER + Paths.SIGNOUT, {
    withCredentials: true,
  })

  return result.data
}
