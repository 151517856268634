import {
  Action,
  DomainState,
  DOMAIN_FILTER_DOMAIN_LIST,
  DOMAIN_FILTER_VALID_EMAIL_LIST,
  DOMAIN_SET_DOMAIN_COUNT,
  DOMAIN_SET_DOMAIN_LIST,
  DOMAIN_SET_DOMAIN_USER_COUNT,
  DOMAIN_SET_FILTER_TEXT,
  DOMAIN_SET_LOADING_DELETE,
  DOMAIN_SET_LOADING_SAVE,
  DOMAIN_SET_SELECTED_DOMAIN,
  DOMAIN_SET_SELECTED_VALID_EMAIL,
  DOMAIN_SET_VALID_EMAIL_COUNT,
  DOMAIN_SET_VALID_EMAIL_FILTER_TEXT,
  DOMAIN_SET_VALID_EMAIL_LIST,
  UserInfoDto,
  UserInfo,
  DOMAIN_SET_USER_TOTAL_COUNT,
  DOMAIN_SET_USER_LIST,
  DOMAIN_SET_USER_LIST_MORE,
} from './types'

export function domainReducer(state: DomainState, action: Action) {
  switch (action.type) {
    case DOMAIN_SET_DOMAIN_LIST: {
      const sort = action.domainInfoList.sort(
        (a, b) => new Date(b.UserCreateDate).getTime() - new Date(a.UserCreateDate).getTime(),
      )
      const items = getDomainInfo(sort)

      return {
        ...state,
        domain: {
          ...state.domain,
          itemsAll: items,
          items,
        },
      }
    }
    case DOMAIN_SET_USER_LIST: {
      const sort = action.userInfoList.users.sort(
        (a, b) => new Date(b.UserCreateDate).getTime() - new Date(a.UserCreateDate).getTime(),
      )
      const items = getDomainInfo(sort)

      return {
        ...state,
        domain: {
          ...state.domain,
          itemsAll: items,
          items,
          nextToken: action.userInfoList.nextToken ?? null,
        },
      }
    }
    case DOMAIN_SET_SELECTED_DOMAIN:
      return {
        ...state,
        domain: {
          ...state.domain,
          selected: action.selectedDomain,
        },
      }
    case DOMAIN_SET_FILTER_TEXT:
      return {
        ...state,
        domain: {
          ...state.domain,
          filterText: action.filterText,
        },
      }
    case DOMAIN_FILTER_DOMAIN_LIST: {
      const filterText = state.domain.filterText.trim()
      return {
        ...state,
        domain: {
          ...state.domain,
          items:
            filterText.length === 0
              ? state.domain.itemsAll
              : state.domain.itemsAll.filter(
                  (item) =>
                    item.Username.toLowerCase().includes(filterText.toLowerCase()) ||
                    item.email?.toLowerCase().includes(filterText.toLowerCase()),
                ),
        },
      }
    }
    case DOMAIN_SET_DOMAIN_COUNT:
      return {
        ...state,
        domain: {
          ...state.domain,
          count: action.domainCount,
        },
      }
    case DOMAIN_SET_DOMAIN_USER_COUNT:
      return {
        ...state,
        domain: {
          ...state.domain,
          userCount: action.domainUserCount,
        },
      }
    case DOMAIN_SET_LOADING_SAVE:
      return {
        ...state,
        loading: {
          ...state.loading,
          save: action.loadingSave,
        },
      }
    case DOMAIN_SET_LOADING_DELETE:
      return {
        ...state,
        loading: {
          ...state.loading,
          delete: action.loadingDelete,
        },
      }
    case DOMAIN_SET_VALID_EMAIL_LIST: {
      return {
        ...state,
        domainValidEmail: {
          ...state.domainValidEmail,
          itemsAll: action.validEmailList,
          items: action.validEmailList,
        },
      }
    }
    case DOMAIN_SET_SELECTED_VALID_EMAIL: {
      return {
        ...state,
        domainValidEmail: {
          ...state.domainValidEmail,
          selected: action.selectedValidEmail,
        },
      }
    }
    case DOMAIN_SET_VALID_EMAIL_FILTER_TEXT: {
      return {
        ...state,
        domainValidEmail: {
          ...state.domainValidEmail,
          filterText: action.filterText,
        },
      }
    }
    case DOMAIN_FILTER_VALID_EMAIL_LIST: {
      const filterText = state.domainValidEmail.filterText.trim()
      return {
        ...state,
        domainValidEmail: {
          ...state.domainValidEmail,
          items:
            filterText.length === 0
              ? state.domainValidEmail.itemsAll
              : state.domainValidEmail.itemsAll.filter((item) =>
                  item.toLowerCase().includes(filterText.toLowerCase()),
                ),
        },
      }
    }
    case DOMAIN_SET_VALID_EMAIL_COUNT: {
      return {
        ...state,
        domainValidEmail: {
          ...state.domainValidEmail,
          count: action.validEmailCount,
        },
      }
    }
    case DOMAIN_SET_USER_TOTAL_COUNT: {
      return {
        ...state,
        userTotalCount: action.userTotalCount,
      }
    }
    case DOMAIN_SET_USER_LIST_MORE: {
      const sort = action.userInfoList.users.sort(
        (a, b) => new Date(b.UserCreateDate).getTime() - new Date(a.UserCreateDate).getTime(),
      )
      const items = getDomainInfo(sort)

      return {
        ...state,
        domain: {
          ...state.domain,
          itemsAll: [...state.domain.itemsAll, ...items],
          items,
          nextToken: action.userInfoList.nextToken ?? null,
        },
      }
    }
    default:
      throw Error('Not Exist Action In domainReducer')
  }
}

function getDomainInfo(domainList: UserInfoDto[]): UserInfo[] {
  return domainList.map((domain) => {
    let emailVerified: boolean = false
    let fid: string = ''
    let locale: string = ''
    let email: string = ''
    let provider: string = 'E-Mail'

    domain.Attributes?.forEach((_attr) => {
      switch (_attr.Name) {
        case 'email_verified':
          emailVerified = !!_attr.Value
          break
        case 'custom:fid':
          fid = _attr.Value
          break
        case 'locale':
          locale = _attr.Value
          break
        case 'email':
          email = _attr.Value
          break
        case 'identities':
          try {
            provider = JSON.parse(_attr.Value)[0].providerName
          } catch (e) {}
      }
    })

    const domainInfo: UserInfo = {
      ...domain,
      emailVerified,
      fid,
      locale,
      email,
      provider,
    }

    return domainInfo
  })
}

export function getDateString(date: Date, withTime?: boolean) {
  const dateFormat = new Intl.DateTimeFormat('ko', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).formatToParts(date)

  const year = dateFormat[0].value
  const month = dateFormat[2].value
  const day = dateFormat[4].value

  if (withTime) {
    const time = new Intl.DateTimeFormat('ko', {
      timeStyle: 'short',
    }).formatToParts(date)

    const timeStr = `${time[2].value}:${time[4].value} ${time[0].value}`
    return `${year}-${month}-${day} ${timeStr}`
  }

  return `${year}-${month}-${day}`
}
