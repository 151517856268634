import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { useUserAPI, useUserTotalCountAPI } from '@/api/hook'
import { useDomainList, useDomainLoading } from '@/module/domain/hook'
import { useOpened, useSelected } from '@/module/home/hook'
import DomainList from './DomainList'
import DomainListHeader from './DomainListHeader'
import { FallbackProps } from 'react-error-boundary'
import { useAuth } from '@/module/auth/hook'
import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { initialUser } from '@/module/auth/context'
import { Navigate, useNavigate } from 'react-router-dom'
import { DomainStateContext } from '@/module/domain/context'

function DomainManagement() {
  const navigate = useNavigate()
  const { productType } = useSelected()
  const { setOpenDomainAddModal } = useOpened()
  const { userQuery, userQueryMore, postMutation } = useUserAPI()
  const { userTotalCountQuery } = useUserTotalCountAPI()
  const { setIsSaveLoading } = useDomainLoading()
  const {
    domain,
    setSelectedDomain,
    setDomainUserCount,
    userTotalCount,
    setUserTotalCount,
    setUserList,
    setUserListMore,
  } = useDomainList()
  const state = useContext(DomainStateContext)
  const nextToken = state?.domain.nextToken

  const loadMoreRef = useRef(null)

  useEffect(() => {
    if (userQueryMore.data) {
      setUserListMore(userQueryMore.data)
    }
  }, [userQueryMore.data])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          nextToken &&
          userQuery?.data?.users?.length &&
          userQuery?.data?.users?.length >= 60
        ) {
          userQueryMore.refetch()
        }
      },
      {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
      },
    )

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current)
      }
    }
  }, [userQuery.data, nextToken])

  useEffect(() => {
    if (userTotalCountQuery.data != null) {
      setUserTotalCount(userTotalCountQuery.data ?? 0)
    }
  }, [])

  useEffect(() => {
    if (userQuery.data != null) {
      setUserList(userQuery.data)
    }
  }, [userQuery.data])

  useEffect(() => {
    setDomainUserCount(domain.itemsAll.length)
  }, [domain.itemsAll])

  useEffect(() => {
    if (postMutation.isLoading) {
      setIsSaveLoading(true)
    }
  }, [postMutation.isLoading])

  return (
    <Fragment>
      <DomainListHeader
        userTotalCount={userTotalCount}
        userFilteredCount={domain.userCount}
        onAddDomainClick={() => {
          setSelectedDomain(null)
          setOpenDomainAddModal(true)
        }}
      />
      <DomainList
        list={domain.itemsAll}
        onClickListItem={(item) => {
          setSelectedDomain(item)
        }}
        onClickEditDomainPage={(item) => {
          navigate(`/${productType}/${encodeURIComponent(item.Username)}`)
        }}
      />
      <div ref={loadMoreRef} style={{ height: '20px', background: 'transparent' }} />
    </Fragment>
  )
}

export function ErrorFallback({ error }: FallbackProps) {
  const { handleSetUser } = useAuth()
  const err = error as AxiosError

  if (err.response?.status === StatusCodes.UNAUTHORIZED) {
    handleSetUser(initialUser)
    alert(`${err.response.status}, ${err.response.statusText}, ${err.message}, ${err.name}`)
    return <Navigate to='/' replace />
  }

  return (
    <div className='flex flex-col w-full h-full overflow-y-auto'>
      <p>{`${err.name}\n${err.message}`}</p>
    </div>
  )
}

export default DomainManagement
