import {
  Action,
  HomeState,
  HOME_SET_OPEN_DOMAIN_ADD_MODAL,
  HOME_SET_OPEN_EMAIL_ADD_MODAL,
  HOME_SET_OPEN_PRODUCT_DROPDOWN,
  HOME_SET_OPEN_PROFILE_DROPDOWN,
  HOME_SET_OPEN_SIDEMENU,
  HOME_SET_SELECTED_DOMAIN,
  HOME_SET_SELECTED_PRODUCT_TYPE,
  HOME_SET_VALID_EMAIL_TYPE,
} from './types'

export function homeReducer(state: HomeState, action: Action) {
  switch (action.type) {
    case HOME_SET_SELECTED_DOMAIN:
      return {
        ...state,
        selected: {
          ...state.selected,
          domain: action.domain,
        },
      }
    case HOME_SET_SELECTED_PRODUCT_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          productType: action.productType,
        },
      }
    case HOME_SET_OPEN_PRODUCT_DROPDOWN:
      return {
        ...state,
        opened: {
          ...state.opened,
          productDropdown: action.openProductDropdown,
        },
      }
    case HOME_SET_OPEN_DOMAIN_ADD_MODAL:
      return {
        ...state,
        opened: {
          ...state.opened,
          domainAddModal: action.openDomainAddModal,
        },
      }
    case HOME_SET_OPEN_EMAIL_ADD_MODAL:
      return {
        ...state,
        opened: {
          ...state.opened,
          emailAddModal: action.openEmailAddModal,
        },
      }
    case HOME_SET_OPEN_PROFILE_DROPDOWN:
      return {
        ...state,
        opened: {
          ...state.opened,
          profileDropdown: action.openProfileDropdown,
        },
      }
    case HOME_SET_OPEN_SIDEMENU:
      return {
        ...state,
        opened: {
          ...state.opened,
          sidemenu: action.openSidemenu,
        },
      }
    case HOME_SET_VALID_EMAIL_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          validEmailType: action.validEmailType,
        },
      }
    default:
      throw Error('Not Exist Action In homeReducer')
  }
}
