import { useContext } from 'react'
import { ProductType } from '@/types'
import { HomeDispatchContext, HomeStateContext, initialDomain, initialUser } from './context'
import {
  HOME_SET_OPEN_DOMAIN_ADD_MODAL,
  HOME_SET_OPEN_EMAIL_ADD_MODAL,
  HOME_SET_OPEN_PRODUCT_DROPDOWN,
  HOME_SET_OPEN_PROFILE_DROPDOWN,
  HOME_SET_OPEN_SIDEMENU,
  HOME_SET_SELECTED_DOMAIN,
  HOME_SET_SELECTED_PRODUCT_TYPE,
  HOME_SET_VALID_EMAIL_TYPE,
} from './types'
import { UserInfo } from '../domain/types'

function useHomeState() {
  const state = useContext(HomeStateContext)
  if (state == null) throw new Error('HomeProvider not found')
  return state
}

function useHomeDispatch() {
  const dispatch = useContext(HomeDispatchContext)
  if (dispatch == null) throw new Error('HomeProvider not found')
  return dispatch
}

export function useSelected() {
  const { selected } = useHomeState()
  const dispatch = useHomeDispatch()

  const setSelectedDomain = (domain?: UserInfo) => {
    if (domain == null) {
      dispatch({
        type: HOME_SET_SELECTED_DOMAIN,
        domain: initialUser,
      })

      return
    }

    dispatch({
      type: HOME_SET_SELECTED_DOMAIN,
      domain,
    })
  }

  const setSelectedProductType = (productType: ProductType) => {
    dispatch({
      type: HOME_SET_SELECTED_PRODUCT_TYPE,
      productType,
    })
  }

  const setIsValidEmailType = (validEmailType: boolean) => {
    dispatch({
      type: HOME_SET_VALID_EMAIL_TYPE,
      validEmailType,
    })
  }

  return {
    productType: selected.productType,
    domain: selected.domain,
    validEmailType: selected.validEmailType,
    setSelectedProductType,
    setSelectedDomain,
    setIsValidEmailType,
  }
}

export function useOpened() {
  const { opened } = useHomeState()
  const dispatch = useHomeDispatch()

  const setOpenDomainAddModal = (openDomainAddModal: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_DOMAIN_ADD_MODAL,
      openDomainAddModal,
    })
  }

  const setOpenEmailAddModal = (openEmailAddModal: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_EMAIL_ADD_MODAL,
      openEmailAddModal,
    })
  }

  const setOpenProductDropdown = (openProductDropdown: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_PRODUCT_DROPDOWN,
      openProductDropdown,
    })
  }

  const setOpenProfileDropwdown = (openProfileDropdown: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_PROFILE_DROPDOWN,
      openProfileDropdown,
    })
  }

  const setOpenSidemenu = (openSidemenu: boolean) => {
    dispatch({
      type: HOME_SET_OPEN_SIDEMENU,
      openSidemenu,
    })
  }

  const {
    productDropdown: openProductDropwdown,
    profileDropdown: openProfileDropdown,
    domainAddModal: openDomainAddModal,
    emailAddModal: openEmailAddModal,
    sidemenu: openSidemenu,
  } = opened

  return {
    openProductDropwdown,
    openProfileDropdown,
    openDomainAddModal,
    openEmailAddModal,
    openSidemenu,
    setOpenSidemenu,
    setOpenDomainAddModal,
    setOpenEmailAddModal,
    setOpenProductDropdown,
    setOpenProfileDropwdown,
  }
}
