import React from 'react'

function Footer() {
  return (
    <footer className='p-4 footer footer-center bg-slate-200 text-base-content'>
      <div>
        <p>© 2024. Flexcil Inc.</p>
      </div>
    </footer>
  )
}

export default Footer
