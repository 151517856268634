import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Footer from '@/components/common/Footer'
import Header from '@/components/common/Header'
import MainLayout from '@/components/common/MainLayout'
import Domain from './Domain'

function MainFrame() {
  const { pathname } = useLocation()
  // const isDomainPage = pathname === '/user' || pathname === '/biz'

  return (
    <MainLayout>
      <Header />
      <Domain />
      <Outlet />
      <Footer />
    </MainLayout>
  )
}

export default MainFrame
