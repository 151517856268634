import React, { Fragment, lazy, Suspense, useCallback, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import DomainListSkeleton from '@/components/DomainListSkeleton'
import { ErrorFallback } from '@/components/DomainManagement'
import DeferredComponent from '@/components/common/DeferredComponent'
import { useDomainList } from '@/module/domain/hook'
import SearchBox from '@/components/common/SearchBox'
import Heading from '@/components/common/Heading'
import { debounce } from 'lodash'

const DomainManagement = lazy(async () => await import('../components/DomainManagement'))

function Domain() {
  const { setFilterText } = useDomainList()
  const [displayText, setDisplayText] = useState('')

  const debouncedFilterText = useCallback(
    debounce((keyword) => {
      setFilterText(keyword)
    }, 300),
    [],
  )
  return (
    <Fragment>
      <Heading container='px-6'>사용자 목록</Heading>
      <SearchBox
        value={displayText}
        placeholder='사용자 이메일 검색'
        onChange={(e) => {
          setDisplayText(e.target.value)
          debouncedFilterText(e.target.value)
        }}
      />
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(err) => console.log(err)}>
        <Suspense
          fallback={
            <DeferredComponent>
              <DomainListSkeleton />
            </DeferredComponent>
          }
        >
          <DomainManagement />
        </Suspense>
      </ErrorBoundary>
    </Fragment>
  )
}

export default Domain
