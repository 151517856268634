import React, { useRef } from 'react'

type SearchBoxProps = {
  value: string
  placeholder: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function SearchBox({ value, placeholder, onChange }: SearchBoxProps) {
  return (
    <div className='px-6 pb-2 flex-row lg:p-4 lg:px-6'>
      <input
        type='search'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className='w-full text-sm input input-bordered input-sm'
      />
    </div>
  )
}

export default SearchBox
