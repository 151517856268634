export enum Paths {
  GetBizDomain = '/api/biz/list',
  UpdateBizDomain = '/api/biz/item',
  GetEduDomain = '/api/edu/list',
  UpdateEduDomain = '/api/edu/item',
  GetEduEmail = '/api/edu/emails',
  UpdateEduEmail = '/api/edu/emails',
  GetUser = '/user',
  GetUserAll = '/user/all',
  GetUserTotalCount = '/user/count',
  GetUserPool = '/user/pool',
  SIGNIN = '/oauth/signin',
  SIGNOUT = '/oauth/signout',
}
